<template>
  <div>
    <div class="w-full flex flex-row justify-between">
      <strong class="font-headline">Notificaciones</strong>
      <i class="bx bx-trash text-lg"
        :class="{
          'text-red-400 cursor-pointer': selection.length,
          'text-gray-400 cursor-not-allowed': !selection.length,
        }"
        @click="deleteNotifications()"></i>
    </div>
    <div v-for="notification in $store.state.notifications.entities" :key="notification.id" class="w-full pt-3">
      <div class="text-md" :class="{ 'cursor-pointer hover:text-purple-400': !notification.read }">
        <div class="w-full flex flex-row" :class="{ 'font-bold': !notification.read }">
          <div class="mr-4">
            <template v-if="notification.read">
              <el-checkbox v-model="selection" :label="notification.id">
                {{ notification.title }}
              </el-checkbox>
            </template>
            <template v-else>
              <div @click="showNotification(notification)">
                <i class="bx bxs-circle text-red-400"></i>
                {{ notification.title }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationService from '@/services/NotificationService';
import { NOTIFICATIONS_MARK_AS_READ } from '@/store/modules/notifications/mutation-types';
import { NotificationTypeEnum } from '@/constants';
import error from '@/mixins/error';

export default {
  name: 'NotificationsPopover',
  mixins: [error],
  data() {
    return {
      selection: [],
    }
  },
  methods: {
    async markAsRead(notificationId) {
      await NotificationService.markAsRead(notificationId);
      await this.$store.dispatch(NOTIFICATIONS_MARK_AS_READ, notificationId);
    },
    async showNotification(notification) {
      if (notification.entity) {
        switch (notification.entity.type) {
          case NotificationTypeEnum.Order:
            if (!notification.read) await this.markAsRead(notification.id);
            await this.$router.push({ name: 'orders.read', params: { id: notification.entity.id } });
            break;
          default:
        }
      } else {
        this.$swal(notification.title, notification.description, "info").then(async (result) => {
          if (result.isConfirmed && !notification.read) await this.markAsRead(notification.id);
        });
      }
    },
    async deleteNotifications() {
      if (this.selection.length) {
        this.$confirm(this.selection.length === 1
          ? `¿Está seguro que desea eliminar esta notificación?`
          : `¿Está seguro que desea eliminar estas notificaciones?`, {
          type: 'warning',
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancelar',
        }).then(async () => {
          try {
            await NotificationService.delete(this.selection);
            this.$toastr.s(this.selection.length === 1
              ? 'La notificación se eliminó correctamente'
              : 'Las notificaciones se eliminaron correctamente');
            this.selection = [];
          } catch (error) {
            this.$toastr.e(this.getErrorMessage(error));
          }
        });
      }
    },
  },
}
</script>
